<template>
  <div class="wrapper">
    <div class="container">
      <div class="icon"><approved /></div>
      <div class="message">Access integration granted</div>
      <div class="subtext">
        The permissions for the integration have been successfully granted.
      </div>
      <router-link to="/dashboard/applications">
        <a class="link">Go to Mekari Developer Center</a>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Approved from "@/assets/images/approved.svg";

export default defineComponent({
  components: {
    Approved,
  },
});
</script>

<style lang="scss" scoped>
.wrapper {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f5f7fa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 400px;
  text-align: center;
}

.icon {
  font-size: 100px;
  color: green;
}
.message {
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
}
.subtext {
  font-size: 14px;
  color: #626b79;
  margin-top: 10px;
}
.link {
  margin-top: 20px;
  display: inline-block;
  font-size: 14px;
  color: #3b82f6;
  text-decoration: none;
}
.link:hover {
  text-decoration: underline;
}
</style>

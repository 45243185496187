<template>
  <div>
    <Error
      :icon="NotFoundIcon"
      message="Page not found"
      subtext="Please check the URL and try again."
      link="/dashboard"
      linkText="Go To Mekari Developer Center"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Error from "@/views/errors/Error.vue";
import NotFoundIcon from "@/assets/images/error/404.svg";

export default defineComponent({
  components: {
    Error,
  },
  data() {
    return {
      NotFoundIcon,
    };
  },
});
</script>
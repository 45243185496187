<template>
  <div class="wrapper" v-if="isLoading">Loading...</div>
  <div class="wrapper" v-else>
    <div class="container">
      <div class="logo-list">
        <div
          class="logo"
          v-for="(componentName, index) in generateLogo()"
          :key="index"
        >
          <component :is="componentName" />
        </div>
      </div>
      <hr />
      <div class="content scrollable" @scroll="handleScroll" ref="scrollable">
        <h1>
          <strong>{{
            $store.state.access_grant.accessGrant?.requestorApp
          }}</strong>
          is requesting integration to <b> {{ products }} </b> with this
          following permissions:
        </h1>
        <h2>Data access</h2>
        <ul class="scopes">
          <li v-for="(value, key) in filteredScopes" :key="key">
            <h3>{{ key }}</h3>
            <ul>
              <li v-for="item in value" :key="item.id">
                {{ item.description_id }}
              </li>
            </ul>
          </li>
        </ul>
        <h2>Continuous access</h2>
        <ul>
          <li>
            {{ $store.state.access_grant.accessGrant?.requestorApp }} may access
            data even when you are not actively using {{ products }}
          </li>
        </ul>
      </div>
      <hr />
      <div class="footer">
        <div class="footer-links">
          <p>
            Learn about Mekari’s
            <a href="https://mekari.com/privacy/">Privacy Policy</a> and
            <a href="https://mekari.com/persyaratan-layanan/"
              >Terms of Service</a
            >
          </p>
        </div>
        <div class="actions">
          <router-link
            class="btn btn-transparent"
            :to="`/dashboard/applications`"
            :style="{ textDecoration: 'none' }"
          >
            Cancel
          </router-link>
          <button
            :disabled="isDisabledBtn"
            @click="approve(uuid)"
            type="button"
            class="btn btn-primary field-submit"
          >
            Allow
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TalentaLogo from "@/assets/images/product/talenta.svg";
import QontakLogo from "@/assets/images/product/qontak.svg";
import KlikpajakLogo from "@/assets/images/product/klikpajak.svg";
import JurnalLogo from "@/assets/images/product/jurnal.svg";
import SignLogo from "@/assets/images/product/sign.svg";
import { ACCESS_GRANT_FETCH_ACCESS_GRANTS } from "@/store-types";
import { SHOW_TOAST } from "@/store-types";
import { TYPE as ToastType } from "vue-toastification";
import {
  arrayToCommaSeparatedString,
  groupFeaturesByProduct,
} from "@/utils/string";
import api from "@/internal-api";
import { Feature } from "@/interfaces/access_grant";

interface Data {
  scopes: string;
  scope_details: { [key: string]: Array<Feature> };
  products: string;
  isLoading: boolean;
  isDisabledBtn: boolean;
}
const STATUS_APPROVED = "approved";

export default defineComponent({
  components: {
    TalentaLogo,
    QontakLogo,
    KlikpajakLogo,
    JurnalLogo,
    SignLogo,
  },
  data(): Data {
    return {
      scopes: "",
      scope_details: {} as { [key: string]: Array<Feature> },
      products: "",
      isLoading: true,
      isDisabledBtn: true,
    } as Data;
  },
  props: {
    uuid: String,
  },
  async mounted() {
    try {
      await this.loadAccessGrant();
      await this.checkEmail();
      await this.checkStatus();
      await this.setScopes();
      await this.setProducts();
    } catch (error) {
      console.error("Error in mounted hook:", error);
    }
  },
  computed: {
    filteredScopes() {
      const filtered: { [key: string]: Array<Feature> } = {};
      for (const key in this.scope_details) {
        filtered[key] = this.scope_details[key].filter(
          (item) => item.description_id
        );
      }
      return filtered;
    },
  },
  methods: {
    handleScroll (event: Event) {
      const target = event.target as HTMLElement;
      const buffer = 1;
      const isAtBottom = Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) <= buffer;
      if (isAtBottom) {
        this.isDisabledBtn = false;
      }
    },
    async loadAccessGrant() {
      const app_uuid = this.uuid || "";
      try {
        await this.$store.dispatch(ACCESS_GRANT_FETCH_ACCESS_GRANTS, app_uuid);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    handleError(err: Error | any) {
      const message = err.response
        ? err.response.data.message
        : "Something went wrong. Try again";
      console.error(message);
      if (message != "Unauthorized") {
        this.$store.dispatch(SHOW_TOAST, {
          message: "Failed to fetch data",
          type: ToastType.ERROR,
        });
        this.$router.push("/notfound");
      }
      return;
    },
    async setScopes() {
      const scope_details =
        this.$store.state?.access_grant?.accessGrant?.scope_details ?? [];
      this.scope_details = groupFeaturesByProduct(scope_details);
    },
    async setProducts() {
      const products =
        this.$store.state?.access_grant?.accessGrant?.products ?? [];
      this.products = arrayToCommaSeparatedString(
        products.map((item) => "Mekari " + item.name)
      );
    },
    async checkEmail() {
      const email = this.$store.state?.access_grant?.accessGrant?.email;
      const currentEmail = this.$store.state.user.email;
      if (!email) return;
      if (email != currentEmail) {
        this.$router.push("/forbidden");
      }
    },
    async checkStatus() {
      const status = this.$store.state?.access_grant?.accessGrant?.status;
      if (!status) return;
      if (status == STATUS_APPROVED) {
        this.$router.push("/access_grant/approved");
      }
    },
    generateLogo() {
      const products =
        this.$store.state?.access_grant?.accessGrant?.products ?? [];
      return products.map((item) => `${item.name.toLowerCase()}-logo`);
    },
    async approve(uuid: string | any) {
      this.isDisabledBtn = true;
      try {
        const { result, error } = await api.accessGrants.approve(uuid);
        if (error) {
          console.error(error);
          this.$store.dispatch(SHOW_TOAST, {
            message: "Failed to allow grant permission",
            type: ToastType.ERROR,
          });
        } else {
          this.$router.push("/access_grant/approved");
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isDisabledBtn = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.wrapper {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f5f7fa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 400px;
  text-align: center;
}

.logo-list {
  display: flex;
  justify-content: center; /* Centers the images horizontally */
  padding: 20px; /* Optional padding around the image list */
}

.logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fb;
}

.logo svg {
  width: 100%;
  height: 100%;
  margin: 10px;
}

.content h1 {
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
  font-weight: normal;
  text-align: left;
  line-height: 24px;
}

.content h2 {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
  text-align: left;
}

ul {
  text-align: left;
  margin: 0;
  padding: 0 0 10px 20px;
  list-style: disc;
}

.scopes h3 {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
  text-align: left;
}

.scopes li {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
  text-align: left;
}

p {
  font-size: 14px;
  color: #666;
  margin: 10px 0;
}

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-links p {
  font-size: 14px;
  text-align: left;
  margin: 0;
  color: black;
}

.actions {
  display: flex;
  gap: 10px;
}

.cancel-button,
.allow-button {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 8px;
  text-decoration: none;
  color: white;
}

.cancel-button {
  color: #626b79;
}

.allow-button {
  background-color: #4caf50;
}

.footer a {
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.scrollable {
  max-height: 400px; /* Adjust the height as needed */
  overflow-y: auto;
  padding-right: 15px; /* Optional: Add padding to avoid content being hidden by scrollbar */
}
</style>

function arrayToCommaSeparatedString(arr: Array<string>) {
  if (arr.length === 0) return "";
  if (arr.length === 1) return arr[0];
  if (arr.length === 2) return arr.join(" and ");

  const lastItem = arr.pop();
  return arr.join(", ") + ", and " + lastItem;
}

interface Feature {
  id: string;
  name: string;
  parent: string;
  description_en: string;
  description_id: string;
}

function groupFeaturesByProduct(arr: Array<Feature>): {
  [key: string]: Array<Feature>;
} {
  const grouped: { [key: string]: Array<Feature> } = {};

  arr.forEach((feature: Feature) => {
    // Extract the product name (e.g., "qontak-chat" from "qontak-chat:all")
    const product = feature.name
      .split(":")[0]
      .replace("talenta-ppev3", "Mekari Talenta PPE V3")
      .replace("talenta-ppe", "Mekari Talenta PPE")
      .replace("esign", "Mekari Sign")
      .replace("jurnal", "Mekari Jurnal")
      .replace("qontak", "Mekari Qontak");

    // Initialize the product group if it doesn't exist
    if (!grouped[product]) {
      grouped[product] = [];
    }

    // Add the feature to the appropriate product group
    grouped[product].push(feature);
  });

  // Assign the grouped features to the data property
  return grouped;
}

export { arrayToCommaSeparatedString, groupFeaturesByProduct };

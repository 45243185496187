
import { defineComponent } from "vue";
import TalentaLogo from "@/assets/images/product/talenta.svg";
import QontakLogo from "@/assets/images/product/qontak.svg";
import KlikpajakLogo from "@/assets/images/product/klikpajak.svg";
import JurnalLogo from "@/assets/images/product/jurnal.svg";
import SignLogo from "@/assets/images/product/sign.svg";
import { ACCESS_GRANT_FETCH_ACCESS_GRANTS } from "@/store-types";
import { SHOW_TOAST } from "@/store-types";
import { TYPE as ToastType } from "vue-toastification";
import {
  arrayToCommaSeparatedString,
  groupFeaturesByProduct,
} from "@/utils/string";
import api from "@/internal-api";
import { Feature } from "@/interfaces/access_grant";

interface Data {
  scopes: string;
  scope_details: { [key: string]: Array<Feature> };
  products: string;
  isLoading: boolean;
  isDisabledBtn: boolean;
}
const STATUS_APPROVED = "approved";

export default defineComponent({
  components: {
    TalentaLogo,
    QontakLogo,
    KlikpajakLogo,
    JurnalLogo,
    SignLogo,
  },
  data(): Data {
    return {
      scopes: "",
      scope_details: {} as { [key: string]: Array<Feature> },
      products: "",
      isLoading: true,
      isDisabledBtn: true,
    } as Data;
  },
  props: {
    uuid: String,
  },
  async mounted() {
    try {
      await this.loadAccessGrant();
      await this.checkEmail();
      await this.checkStatus();
      await this.setScopes();
      await this.setProducts();
    } catch (error) {
      console.error("Error in mounted hook:", error);
    }
  },
  computed: {
    filteredScopes() {
      const filtered: { [key: string]: Array<Feature> } = {};
      for (const key in this.scope_details) {
        filtered[key] = this.scope_details[key].filter(
          (item) => item.description_id
        );
      }
      return filtered;
    },
  },
  methods: {
    handleScroll (event: Event) {
      const target = event.target as HTMLElement;
      const buffer = 1;
      const isAtBottom = Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) <= buffer;
      if (isAtBottom) {
        this.isDisabledBtn = false;
      }
    },
    async loadAccessGrant() {
      const app_uuid = this.uuid || "";
      try {
        await this.$store.dispatch(ACCESS_GRANT_FETCH_ACCESS_GRANTS, app_uuid);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    handleError(err: Error | any) {
      const message = err.response
        ? err.response.data.message
        : "Something went wrong. Try again";
      console.error(message);
      if (message != "Unauthorized") {
        this.$store.dispatch(SHOW_TOAST, {
          message: "Failed to fetch data",
          type: ToastType.ERROR,
        });
        this.$router.push("/notfound");
      }
      return;
    },
    async setScopes() {
      const scope_details =
        this.$store.state?.access_grant?.accessGrant?.scope_details ?? [];
      this.scope_details = groupFeaturesByProduct(scope_details);
    },
    async setProducts() {
      const products =
        this.$store.state?.access_grant?.accessGrant?.products ?? [];
      this.products = arrayToCommaSeparatedString(
        products.map((item) => "Mekari " + item.name)
      );
    },
    async checkEmail() {
      const email = this.$store.state?.access_grant?.accessGrant?.email;
      const currentEmail = this.$store.state.user.email;
      if (!email) return;
      if (email != currentEmail) {
        this.$router.push("/forbidden");
      }
    },
    async checkStatus() {
      const status = this.$store.state?.access_grant?.accessGrant?.status;
      if (!status) return;
      if (status == STATUS_APPROVED) {
        this.$router.push("/access_grant/approved");
      }
    },
    generateLogo() {
      const products =
        this.$store.state?.access_grant?.accessGrant?.products ?? [];
      return products.map((item) => `${item.name.toLowerCase()}-logo`);
    },
    async approve(uuid: string | any) {
      this.isDisabledBtn = true;
      try {
        const { result, error } = await api.accessGrants.approve(uuid);
        if (error) {
          console.error(error);
          this.$store.dispatch(SHOW_TOAST, {
            message: "Failed to allow grant permission",
            type: ToastType.ERROR,
          });
        } else {
          this.$router.push("/access_grant/approved");
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isDisabledBtn = false;
      }
    },
  },
});
